<template>
  <div class="flex mt-40">
    <div class="w50">
      <ResultItem
        v-if="prevalence && prevalence.stunting && prevalence.stunting.length > 0"
        :title="$t('components.titles.prevalenceOfStunting')"
        :subtext="$t('components.labels.greaterOrLessZScores', {
          symbol: '<',
          number: '-2'
        })"
        :prevalence="prevalence.stunting"
        :survey-info="surveyInfo"/>
      <a-empty
        v-if="!prevalence || prevalence.stunting && prevalence.stunting.length === 0"
        :description="$t('components.description.notEnoughDataToDetermineStunting')"/>
      <ResultItem
        v-if="prevalence &&
          prevalence.moderate_stunting && prevalence.moderate_stunting.length > 0"
        class="mt-40"
        :title="$t('components.titles.prevalenceOfModerateStunting')"
        :subtext="$t('components.labels.greaterAndLessZScores', {
          symbol: '<',
          number: '-2',
          symbolTwo: '>=',
          numberTwo: '-3'
        })"
        :prevalence="prevalence.moderate_stunting"
        :survey-info="surveyInfo"/>
      <a-empty
        v-if="!prevalence ||
          prevalence.moderate_stunting && prevalence.moderate_stunting.length === 0"
        class="mt-40"
        :description="$t('components.description.notEnoughDataToDetermineModerateStunting')"/>
    </div>
    <div class="w50">
      <ResultItem
        v-if="prevalence && prevalence.severe_stunting && prevalence.severe_stunting.length > 0"
        :title="$t('components.titles.prevalenceOfSevereStunting')"
        :subtext="$t('components.labels.greaterOrLessZScores', {
          symbol: '<',
          number: '-3'
        })"
        :prevalence="prevalence.severe_stunting"
        :survey-info="surveyInfo"/>
      <a-empty
        v-if="!prevalence || prevalence.severe_stunting && prevalence.severe_stunting.length === 0"
        :description="$t('components.description.notEnoughDataToDetermineSevereStunting')"/>
      <ResultSummary
        v-if="prevalence && prevalence.footer"
        :summary="prevalence.footer"
        :survey-info="surveyInfo"
        :type="type"
        class="mt-20"/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AnthropometryResultsPrevalanceHAZ',
  components: {
    ResultItem: () => import('../result-item'),
    ResultSummary: () => import('../result-summary')
  },
  props: {
    type: {
      type: String,
      default: undefined
    },
    prevalence: {
      type: Object,
      default: undefined
    },
    surveyInfo: {
      type: Object,
      default: undefined
    }
  }
};
</script>

<style lang="scss">

</style>
