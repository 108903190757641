var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex mt-40"},[_c('div',{staticClass:"w50"},[(_vm.prevalence && _vm.prevalence.stunting && _vm.prevalence.stunting.length > 0)?_c('ResultItem',{attrs:{"title":_vm.$t('components.titles.prevalenceOfStunting'),"subtext":_vm.$t('components.labels.greaterOrLessZScores', {
        symbol: '<',
        number: '-2'
      }),"prevalence":_vm.prevalence.stunting,"survey-info":_vm.surveyInfo}}):_vm._e(),(!_vm.prevalence || _vm.prevalence.stunting && _vm.prevalence.stunting.length === 0)?_c('a-empty',{attrs:{"description":_vm.$t('components.description.notEnoughDataToDetermineStunting')}}):_vm._e(),(_vm.prevalence &&
        _vm.prevalence.moderate_stunting && _vm.prevalence.moderate_stunting.length > 0)?_c('ResultItem',{staticClass:"mt-40",attrs:{"title":_vm.$t('components.titles.prevalenceOfModerateStunting'),"subtext":_vm.$t('components.labels.greaterAndLessZScores', {
        symbol: '<',
        number: '-2',
        symbolTwo: '>=',
        numberTwo: '-3'
      }),"prevalence":_vm.prevalence.moderate_stunting,"survey-info":_vm.surveyInfo}}):_vm._e(),(!_vm.prevalence ||
        _vm.prevalence.moderate_stunting && _vm.prevalence.moderate_stunting.length === 0)?_c('a-empty',{staticClass:"mt-40",attrs:{"description":_vm.$t('components.description.notEnoughDataToDetermineModerateStunting')}}):_vm._e()],1),_c('div',{staticClass:"w50"},[(_vm.prevalence && _vm.prevalence.severe_stunting && _vm.prevalence.severe_stunting.length > 0)?_c('ResultItem',{attrs:{"title":_vm.$t('components.titles.prevalenceOfSevereStunting'),"subtext":_vm.$t('components.labels.greaterOrLessZScores', {
        symbol: '<',
        number: '-3'
      }),"prevalence":_vm.prevalence.severe_stunting,"survey-info":_vm.surveyInfo}}):_vm._e(),(!_vm.prevalence || _vm.prevalence.severe_stunting && _vm.prevalence.severe_stunting.length === 0)?_c('a-empty',{attrs:{"description":_vm.$t('components.description.notEnoughDataToDetermineSevereStunting')}}):_vm._e(),(_vm.prevalence && _vm.prevalence.footer)?_c('ResultSummary',{staticClass:"mt-20",attrs:{"summary":_vm.prevalence.footer,"survey-info":_vm.surveyInfo,"type":_vm.type}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }